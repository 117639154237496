import React, { useEffect, Suspense, lazy } from 'react';
import { Provider } from 'react-redux';
import { store } from './store';
import { Router, Route, Switch } from 'react-router-dom';
import './App.css';
import "./fonts/MyFontsWebfontsKit.css";
import { history } from "./History";
import AppLoader from './components/appLoader/appLoader';


const Login = lazy(() => import('./components/Login/Login' /* webpackChunkName: "login" */));
const Home = lazy(() => import('./components/Home/Home' /* webpackChunkName: "home" */));
const Layout = lazy(() => import('./components/Layout/Layout' /* webpackChunkName: "layout" */));
const IBForm = lazy(() => import('./components/IbForm/IbForm' /* webpackChunkName: "ibform" */));
const Proofs = lazy(() => import('./components/Proofs/Proofs' /* webpackChunkName: "proofs" */));
const Disclosures = lazy(() => import('./components/Disclosures/Disclosures' /* webpackChunkName: "disclosures" */));
const CreateIb = lazy(() => import('./components/CreateIb/CreateIb' /* webpackChunkName: "createIb" */));
const Stepper = lazy(() => import('./components/Stepper/Stepper' /* webpackChunkName: "createIb" */));
const PanOcrData = lazy(() => import('./components/kyc/panOcrData/panOcrData' /* webpackChunkName: "panOcrData" */));
const ChequeOcrData = lazy(() => import('./components/bankDetails/chequeOcrData/chequeOcrData' /* webpackChunkName: "chequeOcrData" */));
const UserVerification = lazy(() => import('./Containers/UserVerification' /* webpackChunkName: "user-verification" */));



function App() {

  useEffect(() => {
      // initGA();
      // PageView();
  }, [])

  return (
        <Provider store={store}>
          <Router history={history}>
              <div className="App">
                  <Suspense fallback={<AppLoader />}>
                      <Layout >
                          <Switch>
                              <Route path="/" exact component={Login} />
                              <Route path="/home" component={Home} />
                              <Route path="/ib" component={Stepper} />
                              <Route path="/pan" component={PanOcrData} />
                              <Route path="/bankdetails" component={ChequeOcrData} />
                              <Route path="/create" component={CreateIb} />
                              <Route path="/user_verification" component={UserVerification} />
                              {/* <Route path="/ib/form" component={IBForm} />
                              <Route path="/ib/proofs" component={Proofs} />
                              <Route path="/ib/disclosures" component={Disclosures} />
                              <Route path="/ib/create" component={CreateIb} /> */}
                          </Switch>
                      </Layout>
                  </Suspense>
              </div>
            </Router>
          </Provider>
  );
}

export default App;
